<template>
  <div class="content-box">
    <el-form :inline="true" class="my_element_form">
      <el-form-item label="关键词:">
        <el-input size="medium" v-model="initialParams.imgDesc" placeholder="请输入关键词" clearable></el-input>
      </el-form-item>
      <el-form-item label="栏目:">
        <el-select v-model="initialParams.cmsType" placeholder="选择栏目" clearable style="height:32px;width: 100%;">
          <el-option
            v-for="(val, ind) in newsList"
            :key="ind"
            :label="val.name"
            :value="val.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态:">
        <el-select v-model="initialParams.cmsSta" placeholder="选择状态" clearable style="height:32px;width: 100%;">
          <el-option
            v-for="(val, ind) in cmscmsStaList"
            :key="val"
            :label="val"
            :value="ind">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="medium" @click="handleSearch">搜索</el-button>
      </el-form-item>
      <el-form-item>
        <el-button type="info" size="medium" @click="handleReset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="tableData" :header-cell-style="{background: '#eee', color: '#333'}" style="width: 100%;" highlight-current-row stripe v-loading="initialParams.loading" >
      <el-table-column
        prop="title"
        label="标题"
        align="center"
        min-width="20%">
        <template slot-scope="scope">
          <span class="cursor" style="color: #319BD0;" @click="toDetail(scope.row.id)">
            <span>{{scope.row.title}}</span>
          </span>
          <div v-if="scope.row.isTop == 1" class="ding">顶</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="pidsDesc"
        label="所属栏目"
        align="center"
        min-width="10%">
        <template slot-scope="scope">
          <span>{{scope.row.pidsDesc}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="keyword"
        label="关键字"
        align="center"
        min-width="10%">
      </el-table-column>
      <el-table-column
        prop="image"
        label="题图"
        align="center"
        min-width="12%">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.image"
            style="width: 80px; height: 40px;border-radius: 2px;border: 1px solid rgb(205, 205, 205);"
            :src="$imgBaseUrl + scope.row.image"
            fit="cover"
            :preview-src-list="[$imgBaseUrl + scope.row.image]"></el-image>
          <span v-else style="width: 80px; height: 40px;border-radius: 2px;border: 1px solid rgb(205, 205, 205);"></span>
        </template>
      </el-table-column>
      <el-table-column
        prop="createDate"
        label="创建时间"
        align="center"
        min-width="10%">
        <template slot-scope="scope">
          <span>{{scope.row.createDate[0]}}<br/>{{scope.row.createDate[1]}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="publishTime"
        label="发布时间"
        align="center"
        min-width="10%">
        <template slot-scope="scope">
          <span v-if="scope.row.publishTime">{{scope.row.publishTime[0]}}<br/>{{scope.row.publishTime[1]}}</span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="status"
        align="center"
        label="状态"
        min-width="8%">
        <template slot-scope="scope">
          <span>{{cmscmsStaList[scope.row.status]}}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        min-width="30%">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="editItem(scope.row.id)">编辑</el-button>
          <el-button v-if="scope.row.status == 1" size="mini" type="warning" @click="issue('ok', scope.row.id, scope.row.title)">发布</el-button>
          <el-button v-if="scope.row.status == 2" size="mini" type="warning" @click="issue('no', scope.row.id, scope.row.title)">取消发布</el-button>
          <el-button v-if="scope.row.isTop == 2" size="mini" type="success" @click="zhiding(1, scope.row.id)">置顶</el-button>
          <el-button v-if="scope.row.isTop == 1" size="mini" type="success" @click="zhiding(2, scope.row.id)">取消置顶</el-button>
          <el-button size="mini" type="danger" @click="deleteNews(scope.row.id, scope.row.title)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      @size-change="handleCurrentChange"
      @current-change="handleCurrentChange"
      :current-page.sync="initialParams.pageNo"
      :page-size="initialParams.pageSize"
      layout="total, prev, pager, next, jumper"
      :total="initialParams.total"
      background>
    </el-pagination>
  </div>
</template>

<script>
import { newsList, GetColumnList, newsStatustUpdate, newsDelete } from '@/api/http.js'
export default {
  data () {
    return {
      newsList: '',
      cmscmsStaList: {
        2: '已发布',
        3: '未发布'
      },
      tableData: [],
      initialParams: {
        imgDesc: '',
        cmsType: '',
        cmsSta: '',
        loading: false,
        pageNo: 1,
        pageSize: 10,
        total: 0
      }
    }
  },
  created() {
    this.getAllMenu()
    if (this.$route.query.id) {
      this.initialParams.cmsType = this.$route.query.id
    }
    this.initialData()
  },
  methods: {
    // 条详情
    toDetail (id) {
      const { href } = this.$router.resolve({
        path: '/news/details',
        query: { id }
      })
      window.open(href, '_blank')
    },
    // 编辑
    editItem (id) {
      this.$router.push({ path: '/admin/articleadd', query: { id: id } })
    },
    // 删除文章
    deleteNews (id, title) {
      const text = `您确定要删除标题为“${title}”？`
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        this.initialParams.loading = true
        newsDelete({ id: id }).then(resp => {
          this.initialParams.loading = false
          if (resp.code === '000') {
            this.$message({ message: '删除成功', type: 'success' })
            this.initialData()
          } else {
            this.$message({ message: resp.msg, type: 'error' })
          }
        })
      })
    },
    // 发布--取消发布
    issue (flag, id, title) {
      const params = {}
      params.id = id
      params.status = 2
      let text = `您确定要发布标题为“${title}”？`
      if (flag === 'no') {
        params.status = 1
        text = `您确定取消发布标题为“${title}”？`
      }
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        this.initialParams.loading = true
        newsStatustUpdate(params).then(resp => {
          this.initialParams.loading = false
          if (resp.code === '000') {
            this.$message({ message: '操作成功', type: 'success' })
            this.initialData()
          } else {
            this.$message({ message: resp.msg, type: 'error' })
          }
        })
      })
    },
    // 是否置顶
    zhiding (flag, id) {
      const params = {}
      params.id = id
      params.isTop = flag
      let text = '您确定要置顶吗？'
      if (flag === 2) {
        text = '您确定取消置顶吗？'
      }
      this.$confirm(text, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(res => {
        newsStatustUpdate(params).then(resp => {
          if (resp.code === '000') {
            this.$message({ message: '操作成功', type: 'success' })
            this.initialData()
          } else {
            this.$message({ message: resp.msg, type: 'error' })
          }
        })
      })
    },
    initialData () {
      const params = {
        keyword: this.initialParams.imgDesc,
        cmsType: this.initialParams.cmsType,
        status: this.initialParams.cmsSta,
        pageNo: this.initialParams.pageNo,
        pageSize: this.initialParams.pageSize
      }
      this.initialParams.loading = true
      newsList(params).then(res => {
        this.initialParams.loading = false
        if (res.code !== '000') return this.$message({ type: 'error', message: res.msg })
        const data = res.result.records
        data.forEach((val, ind) => {
          if (val.pidsDesc.indexOf(',') != -1) {
            val.pidsDesc = val.pidsDesc.replace(',', '/')
          }
          val.createDate = val.createDate.split(' ')
          if (val.status !== '1') {
            val.publishTime = val.publishTime.split(' ')
          }
        })
        this.tableData = data
        this.initialParams.total = res.result.total
      })
    },
    // 分页
    handleCurrentChange(val) {
      this.initialParams.pageNo = val
      this.initialData()
    },
    // 搜索
    handleSearch () {
      this.initialParams.pageNo = 1
      this.initialData()
    },
    // 搜索条件重置
    handleReset () {
      this.initialParams.imgDesc = ''
      this.initialParams.pageNo = 1
      this.initialData()
    },
    // 获取目录树
    getAllMenu () {
      this.treeLoading = true
      GetColumnList({}).then(resp => {
        this.treeLoading = false
        if (resp.code === '000') {
          const arr = []
          resp.result.forEach((val, ind) => {
            if (val.type === '1') {
              arr.push({ id: val.id, name: val.name })
              if (val.children) {
                val.children.forEach((vall, indd) => {
                  if (vall.type === '1') {
                    arr.push({ id: vall.id, name: '└ ' + vall.name })
                  }
                })
              }
            }
          })
          this.newsList = arr
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-pagination {
  margin-top: 20px;
  text-align: right;
}
.ding{
  position: absolute;
  top: 5px;
  right: 2px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ff4136;
  line-height: 20px;
  text-align: center;
  color: #ffffff;
  font-size: 12px;
}
</style>
